import { Collapse } from "bootstrap";
import $ from "jquery";

export default class ToggleExpansionRememberer {
  private static readonly storageKeySuffix = "expanded-row-id";

  static init() {
    let expandableToggles = $("[data-bs-toggle='collapse']");

    expandableToggles.each((_, expandableToggleElement) => {
      const expandableToggle = $(expandableToggleElement);

      this.expandRowIfSaved(expandableToggle);
      this.persistRowIdOnToggle(expandableToggle);
    });
  }

  private static expandRowIfSaved = (toggle: JQuery<HTMLElement>) => {
    const targetSelector = toggle.data("bs-target") as string;
    const target = $(targetSelector);

    // no target, nothing to expand
    if (!targetSelector) return;

    const [listKey, currentRowId] = targetSelector.split("_");

    // already expanded, nothing else to do
    if (target.hasClass("show")) return;

    // retrieve the saved rowId to expand
    const savedRowIdToExpand = sessionStorage.getItem(this.storageKey(listKey));

    // current row is not the one saved, do not expand
    if (!savedRowIdToExpand || savedRowIdToExpand != currentRowId) return;

    Collapse.getOrCreateInstance(target[0]).show();
  };

  private static persistRowIdOnToggle = (toggle: JQuery<HTMLElement>) => {
    const targetSelector = toggle.data("bs-target") as string;
    const target = $(targetSelector);

    if (!targetSelector) return;

    const [listKey, currentRowId] = targetSelector.split("_");

    target.on("shown.bs.collapse", () => {
      sessionStorage.setItem(this.storageKey(listKey), currentRowId);
    });

    target.on("hidden.bs.collapse", () => {
      sessionStorage.removeItem(this.storageKey(listKey));
    });
  };

  private static storageKey = (listKey: string) => `${listKey.replace("#", "")}-${this.storageKeySuffix}`;
}
